import React from "react";
import "../css/header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Header = () => {
  const handleWhatsappClick = () => {
    window.open("https://prelink.co/what", "_blank");
  };

  return (
    <div className="header-section">
      <div className="header">
        <h1>Unlock Your Financial Future with AI-Powered Solutions!</h1>
        <h2>
          Empowering Your Journey in Fintech, Job Discovery, and Smart Investing
        </h2>
      </div>
      <div className="whatsapp-icon" onClick={handleWhatsappClick}>
        <FontAwesomeIcon icon={faWhatsapp} />
      </div>
    </div>
  );
};

export default Header;
