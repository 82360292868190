import React from "react";
import "../css/section3.css";

const Section3 = () => {
  return (
    <div className="section3-section">
      <div className="section3">
        <h1>AI Job Discovery</h1>
        <h2>Discover Your Dream Career with AI Assistance</h2>
        <div className="section3-card">
          <h3>Personalized Job Matching</h3>
          <p>
            Let AI match your skills and aspirations with the perfect job
            opportunities, increasing your chances of finding a fulfilling
            career.
          </p>
          {/* <p>
            Enhance your career prospects and potentially earn up to
            <span> 300k USD</span> a month!
          </p> */}
        </div>
        <div className="section3-card">
          <h3>Resume Enhancement</h3>
          <p>
            Utilize AI-driven tools to optimize your resume, making it stand out
            to potential employers in a competitive job market.
          </p>
        </div>
        <div className="section3-card">
          <h3>Interview Preparation</h3>
          <p>
            Access AI-powered interview preparation tools to boost your
            confidence and readiness for job interviews.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section3;
