import React from "react";
import "../css/section5.css";

const Section5 = () => {
  const handleStart = () => {
    window.open("https://prelink.co/what", "_blank");
  };
  return (
    <div className="section5-section">
      <div className="section5">
        <h1>Start Your AI-Powered Journey Today!</h1>
        {/* <p>
          Enjoy the potential to earn up to <span> 300k USD</span> a month!
        </p> */}
        <button className="btnStartNow" onClick={handleStart}>
          Start Now
        </button>
      </div>
    </div>
  );
};

export default Section5;
