import React from "react";
import "../css/section2.css";

const Section2 = () => {
  return (
    <div className="section2-section">
      <div className="section2">
        <h1>AI Fintech Solutions</h1>
        <h2>Revolutionize Your Finances with AI</h2>
        <div className="section2-card">
          <h3>Automated Financial Insights</h3>
          <p>
            Leverage AI algorithms to gain personalized financial insights,
            allowing you to make informed decisions and maximize returns.
            {/* <p>
              Enjoy the potential to earn up to <span>300k USD</span> a month!
            </p> */}
          </p>
        </div>
        <div className="section2-card">
          <h3>Smart Budgeting</h3>
          <p>
            Our AI understands your spending habits, offering tailored budgeting
            strategies to achieve your financial goals faster.
          </p>
        </div>
        <div className="section2-card">
          <h3>Secure Transactions</h3>
          <p>
            Rest easy with our state-of-the-art security measures, ensuring your
            financial transactions are always protected.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section2;
