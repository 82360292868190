import React, { useState } from "react";
import "../css/welcome.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Welcome = () => {
  const [data, setData] = useState({
    name: "",
    age: "",
    phoneNumber: "",
    email: "",
  });

  const { name, age, phoneNumber, email } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    // Validate input based on the field name
    switch (name) {
      case "name":
        // Allow only letters
        updatedValue = value.replace(/[^a-zA-Z]/g, "");
        break;
      case "age":
        // Limit to maximum 3 numbers
        updatedValue = value.replace(/\D/g, "").slice(0, 3);
        break;
      case "phoneNumber":
        // Allow only numbers
        updatedValue = value.replace(/\D/g, "");
        break;
      default:
        break;
    }

    // Update state with the validated value
    setData({ ...data, [name]: updatedValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://v1.nocodeapi.com/djroland/google_sheets/nIwFlRQBoXTiUxRh?tabId=Sheet1",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify([
            [name, age, phoneNumber, email, new Date().toLocaleString()],
          ]),
        }
      );
      await response.json();
      setData({ ...data, name: "", age: "", phoneNumber: "", email: "" });
      setShowModal(false); // Hide the modal after successful submission
      alert("Form submitted successfully!"); // Show success alert

      // Open the provided link in a new tab
      window.open("https://prelink.co/what", "_blank");
    } catch (error) {
      console.log(error);
      alert("Error submitting form. Please try again."); // Show error alert
    }
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="welcome-section">
      <div className="welcome">
        <h1>
          Welcome to Ai Journey, where the future of finance and career
          advancement converge through cutting-edge AI technology.
        </h1>
        {/* <h2>
          Enjoy up to <span>300k USD</span> a month with our innovative
          solutions! Earn Daily Up to <span>$25~$300</span>
        </h2> */}
        <button className="btnGetStarted" onClick={openModal}>
          Get Started
        </button>
      </div>

      {showModal && (
        <div
          className="modal fade show"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Fintech AI-Powered Solutions
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="age" className="form-label">
                      Age:
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="age"
                      name="age"
                      value={age}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phoneNumber" className="form-label">
                      Phone Number:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email:
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <button type="submit" className="btnGetStarted">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`modal-backdrop fade ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
      ></div>
    </div>
  );
};

export default Welcome;
