import React from "react";
import "../css/section4.css";

const Section4 = () => {
  return (
    <div className="section4-section">
      <div className="section4">
        <h1>AI Investment Insights</h1>
        <h2>Maximize Your Wealth with AI-Powered Investing</h2>
        <div className="section4-card">
          <h3>Intelligent Investment Recommendations</h3>
          <p>
            Receive personalized investment recommendations based on your
            financial goals and risk tolerance, ensuring smarter investment
            decisions.
          </p>
          {/* <p>
            Explore investment opportunities that could lead to earning up to{" "}
            <span> 300k USD</span> a month!
          </p> */}
        </div>
        <div className="section4-card">
          <h3>Real-Time Market Analysis</h3>
          <p>
            Stay ahead of market trends with AI-generated real-time analyses,
            helping you capitalize on investment opportunities at the right
            moment.
          </p>
        </div>
        <div className="section4-card">
          <h3>Risk Management</h3>
          <p>
            AI algorithms continually assess and manage risk factors, providing
            a more secure investment experience.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section4;
